
declare var gapi: any;

import Vue from "vue";

export default Vue.extend({
  mounted() {
    gapi.load("auth2", async () => {
      const auth2 = await gapi.auth2.init({
        client_id: process.env.VUE_APP_CLIENT_ID,
        cookiepolicy: "single_host_origin",
        hosted_domain: "urbanhome.co.jp"
      });

      const googleUser = auth2.currentUser.get();
      const profile = googleUser.getBasicProfile();

      const { id_token } = googleUser.getAuthResponse();
      const name = profile.getName();
      const image_url = profile.getImageUrl();

      await this.$store.dispatch("login", { id_token, name, image_url });

      const redirect = localStorage.getItem("redirect");

      if (redirect != null) {
        this.$router.push(redirect);
      } else {
        this.$router.push("/");
      }
    });
  }
});
